(function (document, localStorage, navigator){
    const ONBOARDING_KEY = "onboarding_seen";

    function getCookie(cookieName) {
        let cookie = {};
        document.cookie.split(';').forEach(function(el) {
            let [key,value] = el.split('=');
            cookie[key.trim()] = value;
        })
        return cookie[cookieName];
    }

    function isBot() {
        const userAgent = navigator.userAgent.toLowerCase();

        const patterns = [
            /bot|crawler|spider|googlebot|bingbot|yandexbot|duckduckbot|slurp|bot\//i, // Bots courants
            /facebookexternalhit|twitterbot|linkedinbot|pinterest|slackbot/i, // Bots de réseaux sociaux
            /ahrefsbot|semrushbot|mj12bot|screamingfrog|dotbot|blexbot|/i,// Bots de référencement et d'analyse
            /Google-InspectionTool|GoogleOther|Google-Extended|/i,
            /Googlebot-Image|Googlebot-News|Googlebot-Video/i,
            /adsbot|mediapartners|phantomjs|watsonanalytics|slack-imgproxy/i // Bots publicitaires et autres
        ];

        // Vérifier des motifs typiques de chaînes d'agent utilisateur de bots
        return patterns.some(pattern => pattern.test(userAgent)) ||
            (userAgent.includes('http://') || userAgent.includes('https://') // Certains bots incluent ces informations dans leur UA
        );
    }

    const onboarding_seen = localStorage.getItem(ONBOARDING_KEY) || getCookie(ONBOARDING_KEY) || false;

    if(!onboarding_seen && !isBot()) {
        document.location.href = '/onBoarding/';
        document.cookie        = `${ONBOARDING_KEY}=true; Secure`
        localStorage.setItem(ONBOARDING_KEY, true);
    }
})(document, window.localStorage, navigator);